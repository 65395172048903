.calendar-changes-checked {
    width: 25px;

    .calendar-element .p-inputtext {
        display: none;
        width: 150px
    }

    .calendar-element .p-calendar {
        display: inline-block;
        /* Ensures the icon remains visible */
    }
}