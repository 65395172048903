@import '../../css/colors.scss';

.filterCalendar {
    .inputmask {
        border: 1px solid #dadada;
        width: 90px;
        height: 25px;
    }
}

.template-dataview {

    .p-button,
    .p-selectbutton .p-button.p-highlight {
        background-color: $color-lessblack !important;
    }

    .p-sortable-column.p-highlight .p-sortable-column-icon {
        color: $color-filter-selected-fbib !important;
    }

    .p-datatable .p-sortable-column .p-sortable-column-badge {
        background: $color-filter-selected-fbib !important;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        background: $color-filter-selected-fbib !important;
    }

    .p-datepicker table td>span.p-highlight {
        background: $color-filter-selected-fbib !important;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        background: $color-filter-selected-fbib !important;
    }

    .eaa-filter-active {
        background-color: $color-filter-checkbox-selected !important;
    }

    .eaa-filter-active-fbib {
        background-color: $color-filter-checkbox-selected-fbib !important;
    }

    .p-column-filter-menu-button {
        display: none;
    }
}