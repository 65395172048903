$color-lessblack: #494c4f;
$color-blue: #276AB7;
$color-red: #F06E64;
$color-green: #0B8E7A;
$color-green-fbib: #04943b;
$color-grey: #A7A7A7;
$color-orange: #F4A261;
$color-tagblue: #008CDC;
$color-transfer: #425170;
$color-filter-selected :#71A5D8;
$color-filter-selected-fbib : #6db88a;
$color-filter-checkbox-selected-fbib :#09411e;
$color-filter-checkbox-selected :#3C4E60;
$color-badge-danger: #F4B6B6;
$color-badge-warning: #FFD84C;
$color-badge-success: #7BEE94;


.color-background-fbib {
    background: $color-green-fbib !important;
}

.color-background-lessblack {
    background-color: $color-lessblack !important;
}

.color-background-blue {
    background-color: $color-blue;
}

.color-contact-selected {
    border-color: $color-blue !important;
    border-width: 2px !important;
}

.color-contact-inactive {
    background-color: $color-grey !important;
}

.color-lessblack {
    color: $color-lessblack !important;
}

.color-text-blue {
    color: $color-blue !important;
}

.color-text-tagblue {
    color: $color-tagblue !important;
}


.color-text-red {
    color: $color-red !important;
}

.color-text-green {
    color: $color-green !important;
}

.color-text-grey {
    color: $color-grey !important;
}

.color-text-orange {
    color: $color-orange !important;
}

.color-text-fbib {
    color: $color-green-fbib !important;
}

.color-tag-red {
    background-color: $color-red !important;
}

.color-tag-green {
    background-color: $color-green !important;
}

.color-tag-grey {
    background-color: $color-grey !important;
}

.color-tag-orange {
    background-color: $color-orange !important;
}

.color-tag-blue {
    background-color: $color-blue !important;
}

.color-tag-tagblue {
    background-color: $color-tagblue !important;
}

.color-tag-tagtransfer {
    background-color: $color-transfer !important;
}